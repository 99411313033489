import React, { useEffect, useState, useCallback, useRef } from 'react';
import Swiper from 'swiper';

import cls from 'classnames';

function ColorPicker(props) {
  const { name, title, backgroundImage, initial } = props;

  const colors = global.colorPickerData[name];

  const backgroundRef = useRef(null);
  const [ current, setCurrent ] = useState(+initial - 1);
  const [ color, setColor ] = useState(colors[+initial]);

  // console.log("!!!", colors[initial], current, initial, typeof current, typeof initial)
  // .map(color => {
  //   const [, number, name] = color.match(/(\d+)_(\w+)/);
  //   return {
  //     number: +number,
  //     name,
  //     filename: color
  //   }
  // })
  // .sort((a, b) => a.number - b.number);

  useEffect(() => {
    backgroundRef.current.style.backgroundImage = backgroundImage;

    new Swiper('.color-picker-container', {
      // slidesPerView: isMobile ? 5 : 10,
      // spaceBetween: 10,
      // width: '400',
      initialSlide: +initial,
      navigation: {
        nextEl: '.color-picker-arrow-right',
        prevEl: '.color-picker-arrow-left',
      },
      breakpoints: {
        250: {
          slidesPerView: 3,
          spaceBetween: 10
        },
        319: {
          slidesPerView: 4,
          spaceBetween: 10
        },
        415: {
          slidesPerView: 5,
          spaceBetween: 10
        },
        480: {
          slidesPerView: 6,
          spaceBetween: 10
        },
        610: {
          slidesPerView: 7,
          spaceBetween: 10
        },
        550: {
          slidesPerView: 8,
          spaceBetween: 10
        },
        1064: {
          slidesPerView: 10,
          spaceBetween: 10
        }
      }
    });
  }, []);

  const onClick = useCallback((number) => {
    setCurrent(number);
    setColor(colors[number]);

    // background.style.backgroundImage = `url(/painted_images/${name}/${number}.jpg)`;

    //var src = $('#test').css('background-image');
    // var url = src.match(/\((.*?)\)/)[1].replace(/('|")/g,'');

    const imageUrl = `/painted_images/${name}/${number + 1}.jpg`;
    var img = new Image();

    const background = backgroundRef.current;
    img.onload = function() {
      background.style.backgroundImage = `url(${this.src})`;
      img = null;
    }
    img.src = imageUrl;

  }, [backgroundRef]);

  const colorsNodes = colors.map((color, index) => (
    <div className="swiper-slide">
      <div
        onClick={onClick.bind(null, index)}
        className="color-picker-color"
        style={{
          // backgroundImage: `url(/colors/${name.toUpperCase()}/${color.filename}`,
          backgroundColor: color.value,
          borderColor: current == index ? 'white' : 'black',
          borderWidth: current == index ? '5px' : '2px',
        }}
      >
      </div>
    </div>
  ));

  return (
    <>
      <div
        ref={backgroundRef}
        className="color-picker-background"
      />
      <h2 style={{color: 'white'}}>Подбор цвета</h2>
      <div className="description" style={{color: 'white', marginTop: '-20px'}}>
        {title}
      </div>

      <div className="color-picker-wrapper">
      <div className="color-picker-color-name">ЦВЕТ: {color.name}</div>
      <div className="arrow-left-icon color-picker-arrow-left" />
        <div className="color-picker-container swiper-container">
          <div className="swiper-wrapper">
            {colorsNodes}
          </div>
        </div>
        <div className="arrow-right-icon color-picker-arrow-right" />
      </div>
    </>
  );
}

export default ColorPicker;