import React, { useEffect, useState, useCallback, useRef } from 'react';
import cls from 'classnames';
import useHover from '../useHover';
import './MasterClass.scss';

import DayPicker, { LocaleUtils, DateUtils } from "react-day-picker";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import validate, { isValidDate } from '../validate';
import post from '../post';
import "react-day-picker/lib/style.css";

import dateFnsFormat from 'date-fns/format';
import dateFnsParse from 'date-fns/parse';

const WEEKDAYS_SHORT = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб'];
const today = new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate());

function getDisabledDays(date) {
  const parseDate = new Date(date);
  if (parseDate.getTime() > today.getTime() && parseDate.getDay() === 6) {
    return false;
  }
  return true;
}

function formatWeekdayShort(i) {
  return WEEKDAYS_SHORT[i];
}

function parseDate(str, format, locale) {
  const parsed = dateFnsParse(str, format, new Date(), { locale });
  if (DateUtils.isDate(parsed)) {
    return parsed;
  }
  return undefined;
}

function formatDate(date, format, locale) {
  return dateFnsFormat(date, format, { locale });
}

function formatMonthTitle(month, locale) {
  const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Ноябрь',
    'Декабрь',
  ];

  return `${months[month.getMonth()]} ${month.getFullYear()}`;
}

const modifiersStyles = {
  birthday: {
    color: 'white',
    backgroundColor: '#ffc107',
  },
  thursdays: {
    color: '#ffc107',
    backgroundColor: '#fffdee',
  },
  saturdays: {
    color: '#ffc107',
    backgroundColor: '#fffdee',
  }
};

function MasterClass() {
  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const dateRef = useRef(null);

  const [ isFinal, setIsFinal ] = useState(false);
  const [ selectedDay, setSelectedDay ] = useState(null);
  const FORMAT = 'dd.MM.yyyy';

  const onDayChange = useCallback((day) => {
    setSelectedDay(day);
    dateRef.current.input.style.borderColor = 'white';
  }, [dateRef]);

  const onChange = useCallback((e) => {
    e.target.style.borderColor = 'white';
  }, []);

  const onSubmit = useCallback((type, e) => {
    e.preventDefault();

    const fields = [
      { input: phoneRef.current, type: 'phone'},
      { input: emailRef.current, type: 'email'},
      { input: dateRef.current.input },
    ];

    const isValid = !validate(fields);

    const dateValue = dateRef.current.input.value;
    const parsedDate = parseDate(dateValue, 'dd.MM.yyyy');
    const isValidDate = parsedDate && parsedDate.getDay() === 6 && parsedDate.getTime() >= today.getTime()
    if (!isValidDate) {
      dateRef.current.input.style.borderColor = 'tomato';
      if (isValid) {
        dateRef.current.input.focus();
      }
    }

    if (isValid && isValidDate) {
      setIsFinal(true);
      post(
        `/wp-admin/admin-ajax.php?action=collect_data&form=${type}`,
        {
          phone: phoneRef.current.value,
          email: emailRef.current.value,
          date: dateRef.current.input.value,//dateFnsFormat(selectedDay, 'dd.MM.yyyy')
        },
        (err, res) => {
          //console.log(err, res);
        }
      );
    }
  }, [phoneRef, emailRef, dateRef, selectedDay]);

  return (
    // <div className="single-column" id="master-class">
    //     <div className="wrapper master-class">
    //       <div className="master-class-background" />
    //       <div className="about">
    //         <h1 className="master-class-title" style={{textAlign: 'left'}}>БЕСПЛАТНЫЙ<br />МАСТЕР-КЛАСС</h1>
    //         <div className="master-class-desc">ПАРКОВКА НА ТЕРРИТОРИИ ОФИСА БЕСПЛАТНО</div>
    //       </div>

    //       <form className="master-class-form">
    <>
          {isFinal ? <>
            <div className="feedback-final" style={{maxWidth: '196px', minWidth: '182px', padding: '20px 0', boxSizing: 'border-box'}}>
              <div className="feedback-title">Спасибо за&nbsp;вашу заявку,</div>
              <div className="feedback-text" >Наш менеджер свяжется с вами по указанному телефону в самое ближайшее время</div>
            </div>
            </> : <>
              <h2 className="title">ЗАПИСЬ</h2>
              <div className="master-class-controls">
                <input placeholder="Телефон" name="phone" ref={phoneRef} onChange={onChange}></input>
                <input placeholder="e-mail" name="email" ref={emailRef} onChange={onChange}></input>
                <DayPickerInput
                  ref={dateRef}
                  placeholder="ДД.ММ.ГГГГ"
                  formatDate={formatDate}
                  format={FORMAT}
                  parseDate={parseDate}
                  // placeholder={`${dateFnsFormat(new Date(), FORMAT)}`}
                  onDayChange={onDayChange}
                  selectedDay={selectedDay}
                  inputLabelProps={{
                    shrink: true,
                  }}
                  modifiersStyles={modifiersStyles}
                  dayPickerProps={{
                    disabledDays: getDisabledDays,
                    modifiersStyles: modifiersStyles,
                    firstDayOfWeek: 1,
                    fromMonth: today,
                    localeUtils: {
                      ...LocaleUtils,
                      formatMonthTitle,
                      formatWeekdayShort
                    }
                  }}
                />
                <button className="button-neutral" onClick={onSubmit.bind(null, 'call')}>
                  Заказать обратный звонок
                </button>
                <button className="button-white" onClick={onSubmit.bind(null, 'master-class')}>
                  ЗАПИСАТЬСЯ НА МАСТЕР-КЛАСС
                </button>
              </div>
            </>}
</>
          // </form>
        // </div>
      // </div>
  );
}

export default MasterClass;
