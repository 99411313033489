import React, { useEffect, useState, useCallback, useRef } from 'react';
import { MetroSpinner } from "react-spinners-kit";
import Carousel, { Modal, ModalGateway } from 'react-images';

import './Portfolio.scss';

import {carouselComponents} from 'react-images';
import post from '../post';

const BaseView = carouselComponents.View;

// transparent pixel: http://png-pixel.com/
const pixel = {source: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='}
const overscan = 3

function View(props) {
  const {currentIndex, index, data} = props
  const inBounds = Math.abs(currentIndex - index) <= overscan

  return <BaseView {...props} data={inBounds ? data : pixel} />
}

function svgPlaceholder(width, height) {
  return `data:image/svg+xml,%3Csvg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 ${width} ${height}"%3E%3C/svg%3E`;
}

function loadImage(url) {
  return new Promise(resolve => {
    const image = new Image();
    image.addEventListener('load', () => {
      resolve(image);
    });
    image.addEventListener('error', () => {
      resolve(image);
    });
    image.src = url;
  });
}



function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split('&');
  for (var i = 0; i < vars.length; i++) {
      var pair = vars[i].split('=');
      if (decodeURIComponent(pair[0]) == variable) {
          return decodeURIComponent(pair[1]);
      }
  }
  return variable;
}

function Portfolio() {
  const [data, setData] = useState(() => {
    return {
      categories: [],
      images: []
    };
  });

  const loadingImages = useRef(null);
  const [isLoading, setIsLoading] = useState(false);
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);
  const [images, setImages] = useState([]);
  const [lightboxImages, setLightboxImages] = useState([]);
  const [filter, setFilter] = useState('Смотреть всё');

  const [page, setPage] = useState(1);
  const [pagesCount, setPagesCount] = useState(1);

  const filterImages = useCallback((data, filter, page, isLoading) => {
    let images = data.images[filter];

    if (filter === 'Смотреть всё') {
      images = data.categories.reduce((all, category) => {
        return all.concat(data.images[category]);
      }, []);
    }

    setLightboxImages(images.map((image) => ({
      source: image.replace(/(.*)-\d+[xX]\d+(\.\w+)/g, '$1$2')
    })));

    setPagesCount(Math.ceil(images.length / 9));
    const newImages = images.slice(page * 9 - 9, page * 9);
    if (isLoading) {
      loadingImages.current = null;
      setIsLoading(true);

      loadingImages.current = images.map(image => loadImage(image));
      Promise.all(loadingImages.current).then(() => {
        if (loadingImages.current) {
          setImages(newImages);
          setIsLoading(false);
        }
      });
    } else {
      setImages(newImages);
    }
  }, []);

  const openLightBox = useCallback((index) => {
    setLightboxIndex((page - 1) * 9 + index);
    setLightboxIsOpen(true);
  }, [page]);

  const closeLightBox = useCallback(() => {
    setLightboxIsOpen(false);
  }, []);

  useEffect(() => {
    const category = getQueryVariable('category');

    post(`/wp-admin/admin-ajax.php?action=get_portfolio&category=${category}`, null, (err, res) => {
      const data = res && res.data || { images: {} };

      const all = [...(data.images.all || [])];
      delete data.images.all;

      const categories = Object.keys(data.images || {}).sort((a, b) => a.localeCompare(b));
      categories.push('Смотреть всё');

      const newData = {
        categories,
        images: { ...data.images, 'Смотреть всё': all }
      };

      setData(newData);

      const hashFilter = decodeURI((window.location.hash || '').substr(1));
      if (categories.includes(hashFilter)) {
        setFilter(hashFilter);
        filterImages(newData, hashFilter, 1, false);
      } else {
        filterImages(newData, filter, 1, false);
      }
    });
  }, []);

  const incPage = useCallback(() => {
    if (page < pagesCount) {
      const newPage = page + 1;
      setPage(newPage);
      filterImages(data, filter, newPage, true);
    }
  }, [page, pagesCount, data, filter]);

  const decPage = useCallback(() => {
    if (page >= 2) {
      const newPage = page - 1;
      setPage(page - 1);
      filterImages(data, filter, newPage, true);
    }
  }, [page, data, filter]);

  const onFilterChange = useCallback((category) => {
    setPage(1);
    setFilter(category);
    filterImages(data, category, 1);
  }, [data]);

  return (
    <div className="Portfolio">
      {/* <Nav/> */}
      {/* <div className="single-column">
        <div className="wrapper portfolio">
          <h1>Портфолио</h1>
          <div className="portfolio-categories">
            <a href="/portfolio/?category=stuc">
              <div
                className="portfolio-category"
                style={{backgroundImage: 'url(/images/portfolio_kind_stuc@3x.jpg)'}}
              >
                <div className="portfolio-category-title">STUC GRANITO<br/>STUC DECO</div>
              </div>
            </a>
            <a href="/portfolio/?category=kalk">
              <div
              className="portfolio-category"
              style={{backgroundImage: 'url(/images/portfolio_kind_kalk@3x.jpg)'}}
              >
                <div className="portfolio-category-title">KALK</div>
              </div>
            </a>
            <a href="/portfolio/?category=kalei">
              <div
                className="portfolio-category"
                style={{backgroundImage: 'url(/images/portfolio_kind_kalei@3x.jpg)'}}
              >
                <div className="portfolio-category-title">KALEI</div>
              </div>
            </a>
            <a href="/portfolio/?category=partners">
              <div
                className="portfolio-category"
                style={{backgroundImage: 'url(/images/portfolio_kind_partners@3x.jpg)'}}
              >
                <div className="portfolio-category-title">НАШИ ПРОЕКТЫ</div>
              </div>
            </a>
          </div>
          <a href="/portfolio/?category=all" className="portfolio-see-all">
            <button className="portfolio-see-all">Смотреть всё</button>
          </a>
        </div>
      </div> */}
      <div className="single-column">
        <div className="wrapper">
          <h1>Портфолио</h1>
          <a href="/portfolio"><button style={{marginBottom: '40px'}}>Вернуться назад</button></a>
          <div className="portfolio-gallery">
          <div className="portfolio-filters">
            <div className="portfolio-filters-title">Категории</div>
            <ul className="portfolio-filters-list">
              {data.categories.map(category => (
                <li>
                  <a
                    href={`#${category}`}
                    onClick={onFilterChange.bind(null, category)}
                    style={{fontWeight: category === filter ? 'bold' : 'normal'}}
                  >
                    {category}
                  </a>
                </li>
              ))}
            </ul>
          </div>
          <div className="portfolio-images-column">
            <div className="portfolio-images">
              {images.map((image, i) => (
                <div className="portfolio-box">
                  <div className="portfolio-image-wrap" onClick={openLightBox.bind(null, i)}>
                    <img src={image} />
                  </div>
                </div>
              ))}
            </div>
            {pagesCount > 1 ? <div className="portfolio-pages">
              <div className="portfolio-left-arrow arrow-left-icon" onClick={decPage}></div>
              <div className="portfolio-page-number">
                {isLoading
                  ? <MetroSpinner size={24} color="#000000" loading={true} />
                  : <span>{page} / {pagesCount}</span>
                }
              </div>
              <div className="portfolio-right-arrow arrow-right-icon" onClick={incPage}></div>
            </div> : null}
          </div>
        </div>
      </div>
      </div>
      <ModalGateway>
          {lightboxIsOpen && !isLoading ? (
            <Modal onClose={closeLightBox} allowFullscreen={false}>
              <Carousel
                currentIndex={lightboxIndex}
                views={lightboxImages}
                components={{View: View}}
              />
            </Modal>
          ) : null}
        </ModalGateway>
      {/* <Footer/> */}
    </div>
  );
}

export default Portfolio;
