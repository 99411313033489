export const SYMBOLS_PAT = /\D/g;
export const PHONE_PAT = /^([0-9()\+ -]*)$/;
export const MAIL_PAT = /^((([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+(\.([a-z]|\d|[!#\$%&'\*\+\-\/=\?\^_`{\|}~]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])+)*)|((\x22)((((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(([\x01-\x08\x0b\x0c\x0e-\x1f\x7f]|\x21|[\x23-\x5b]|[\x5d-\x7e]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(\\([\x01-\x09\x0b\x0c\x0d-\x7f]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))))*(((\x20|\x09)*(\x0d\x0a))?(\x20|\x09)+)?(\x22)))@((([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|\d|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))\.)+(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])|(([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])([a-z]|\d|-|\.|_|~|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])*([a-z]|[\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])))$/i;

export const isRequired = v => v != null && v !== '' && v !== false && !Object.is(v, NaN);
export const isPhone = (v) => {
  v = v.replace(SYMBOLS_PAT, '');
  return v.length >= 7 && v.length <= 14 && v.match(PHONE_PAT);
};

export const isEmail = v => v.match(MAIL_PAT);

export const isValidDate = function(date) {
  if (!date || date.length !== 8) {
    return false;
  }
  const matches = /^(\d{2})(\d{2})(\d{4})$/.exec(date) || [];

  const d = matches[1];
  const m = matches[2] - 1;
  const y = matches[3];
  const composedDate = new Date(y, m, d);

  return (
    composedDate.getDate() == d && composedDate.getMonth() == m && composedDate.getFullYear() == y
  );
};

export const validate = (input, type) => {
  const { value } = input;
  if (
    !isRequired(value)
    || (type === 'phone' && !isPhone(value))
    || (type === 'email' && !isEmail(value))
    || (type === 'date' && !isValidDate((value || '').replace(/\./g, '')))
  ) {
    input.style.borderColor = 'tomato';
    return true;
  }
}

export default function(fields) {
  let isFocused = false;
  fields.forEach(field => {
    if (validate(field.input, field.type) && !isFocused) {
      field.input.focus();
      isFocused = true;
    }
  });
  return isFocused;
}