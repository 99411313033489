
export default function post(url, data, callback) {
  if (typeof window !== "undefined") {
    var request = new XMLHttpRequest();
    request.open('POST', url, true);

    request.onload = function() {
      if (request.status >= 200 && request.status < 400) {
        callback(false, JSON.parse(request.responseText));
      } else {
        callback(true);
      }
    };

    request.onerror = function() {
      callback(true);
    };

    request.send(JSON.stringify(data));
  }
}