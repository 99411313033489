import React, { useRef, useState, useCallback, useEffect } from 'react';
import './Calculator.scss';
import cls from 'classnames';

import data from '../calculator.json';
import validate from '../validate';
import post from '../post';

import animateHover from '../animateHover';

function isElementInViewport (el) {
  var rect = el.getBoundingClientRect();

  return (
      rect.top >= 0 &&
      rect.left >= 0 &&
      rect.bottom <= (window.innerHeight || document.documentElement.clientHeight) && /* or $(window).height() */
      rect.right <= (window.innerWidth || document.documentElement.clientWidth) /* or $(window).width() */
  );
}

function scrollToTop() {
  if (document.documentElement.clientWidth < 800) {
    if (!isElementInViewport(document.querySelector('.calculator h1'))) {
      document.querySelector('.calculator').scrollIntoView();
    }
  }
}

function FirstStep(props) {
  const { onSelect, items } = props;

  useEffect(() => {
    animateHover('.calculator-products li', 'calculator-product-active');
  }, []);

  const onClick = useCallback((e) => {
    const { currentTarget } = e;

    const titleNode = currentTarget.querySelector('.calculator-product-title');
    const type = currentTarget.getAttribute('data-types');

    onSelect(titleNode.textContent, type);
  }, [onSelect]);

  //calculator-product-active
  return (
    <div>
      <div className="calculator-product-step-title">
        На какую поверхность планируете наносить декоративный материал?
      </div>
      <ul className="calculator-products">
        {items.map(item => (
          <li className={cls('calculator-product', `calculator-product-count-${items.length}`)} data-types={item.type} onClick={onClick}>
            <div className="calculator-product-title">{item.name}</div>
            <div className="calculator-product-wrap">
              <img src={item.image} className="calculator-product-image brand-slide-cover" />
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
}

function SecondStep(props) {
  const { onSelect, className, items } = props;

  useEffect(() => {
    animateHover('.calculator-products li', 'calculator-product-active');
  }, []);

  const onClick = useCallback((e) => {
    const { currentTarget } = e;

    const titleNode = currentTarget.querySelector('.calculator-product-title');
    const type = currentTarget.getAttribute('data-types');

    onSelect(titleNode.textContent, type);
  }, [onSelect]);

  return (
    <ul className={cls('calculator-products', className)}>
      {items.map(item => (
        <li className={cls('calculator-product', `calculator-product-count-${items.length}`)} data-types={item.type} onClick={onClick}>
          <div className="calculator-product-title">{item.name}</div>
          <div className="calculator-product-wrap">
            <img src={item.image} className="calculator-product-image brand-slide-cover" />
          </div>
        </li>
      ))}
    </ul>
  );
}

function ThirdStep(props) {
  const { onSelect } = props;

  return (
    <div className="calculator-input-step calculator-input-step-one">
      <div className="calculator-product-step-title">
        Нужна услуга по нанесению декоративных покрытий?
      </div>

      <div className="calculator-product-step-controls">
        <div className="calculator-product-step-row">
          <button onClick={onSelect.bind(null, '229')}>Да</button>
          <button onClick={onSelect.bind(null, '231')}>Нет</button>
        </div>
      </div>
    </div>
  );
}

function FourthStep(props) {
  const { onSelect } = props;

  const ref = useRef(null);

  const onChange = useCallback((e) => {
    e.target.style.borderColor = 'black';
  }, []);

  const onClick = useCallback((e) => {
    const fields = [
      { input: ref.current},
    ];

    if (!validate(fields)) {
      onSelect(ref.current.value);
    }
  }, [ref, onSelect]);

  return (
    <div className="calculator-input-step calculator-input-step-two">
      <div className="calculator-product-step-title">
        Какая примерная площадь декорируемых поверхностей?
      </div>

      <div className="calculator-product-step-controls">
        <input ref={ref} placeholder="Введите ответ" onChange={onChange} />
        <button onClick={onClick}>Продолжить</button>
      </div>
    </div>
  );
}

function FifthStep(props) {
  const { onSubmit } = props;

  const nameRef = useRef(null);
  const phoneRef = useRef(null);
  const emailRef = useRef(null);

  const onChange = useCallback((e) => {
    e.target.style.borderColor = 'black';
  }, []);

  const onClick = useCallback((e) => {
    const fields = [
      { input: nameRef.current},
      { input: phoneRef.current, type: 'phone'},
      { input: emailRef.current, type: 'email'},
    ];

    if (!validate(fields)) {
      onSubmit(nameRef.current.value, phoneRef.current.value, emailRef.current.value);
    }
  }, [nameRef, phoneRef, emailRef, onSubmit]);

  return (
    <div className="calculator-input-step">
      <div className="calculator-product-step-title">
        Ваш расчёт готов! Для получения результатов укажите свой номер телефона.
      </div>

      <div className="calculator-product-step-controls">
      <input ref={nameRef} name="name" placeholder="Введите ваше имя" onChange={onChange} />
        <input ref={phoneRef} name="phone" placeholder="Введите номер телефона" onChange={onChange} style={{marginTop: '40px'}} />
        <input ref={emailRef} name="email" placeholder="Введите вашу почту" onChange={onChange} style={{marginTop: '40px'}} />
        <button onClick={onClick} style={{marginTop: '40px'}}>Узнать стоимость</button>
      </div>
    </div>
  );
}

function FinalStep(props) {
  const { onSubmit } = props;

  const onClick = useCallback((e) => {
    onSubmit(0);
  }, [onSubmit]);

  return (
    <div className="calculator-final-step">
      <div className="feedback-final">
        <div className="feedback-title" style={{color: 'black'}}>Спасибо за&nbsp;вашу заявку,</div>
        <div className="feedback-text" style={{color: 'black'}}>Наш менеджер свяжется с вами по указанному телефону в самое ближайшее время</div>
      </div>
      <div className="calculator-product-step-controls">
        <button onClick={onClick}>Вернуться к шагу 1</button>
      </div>
    </div>
  );
}

function Steps(props) {
  const { activeStep, stepsCount, onSelect } = props;

  const stepsNode = [];

  for (let i = 0; i < stepsCount; i++) {
    stepsNode.push((
      <li
        className={i <= activeStep ? 'calculator-step-active' : ''}
        onClick={onSelect.bind(null, i < activeStep ? i : null)}
      >
        <div className="calculator-step-circle"></div>
        <div className="calculator-step-title">{i + 1} шаг</div>
      </li>
    ))
  }

  return (
    <ul className="calculator-steps">
      {stepsNode}
    </ul>
  );
}

function Calculator(props) {
  const { name, product, short } = props;

  const firstStepItems = data[name];

  const [activeStep, setActiveStep] = useState(0);
  const [state, setState] = useState(short ? {
    text: name,
    material: []
  } : {});

  const onProductSelect = useCallback((text, type) => {
    setState({
      typeId: type,
    });

    scrollToTop();
    setActiveStep(1);
  }, []);

  const onMaterialSelect = useCallback((material, type) => {
    setState({
      ...state,
      materialId: type
    });

    scrollToTop();
    setActiveStep(2);
  }, [state]);

  const onServiceSelect = useCallback((service) => {
    setState({ ...state, serviceId: service });

    scrollToTop();
    setActiveStep(3);
  }, [state]);

  const onAreaSelect = useCallback((area) => {
    setState({ ...state, area });

    scrollToTop();
    setActiveStep(4);
  }, [state]);

  const onSubmit = useCallback((name, phone, email) => {
    setState({ ...state, name, phone });

    post(
      `/wp-admin/admin-ajax.php?action=collect_data&form=calculator`,
      { ...state, name, phone, email },
      (err, res) => {
        //console.log(err, res);
      }
    );

    scrollToTop();
    setActiveStep(5);
  }, [state]);

  const onStepSelect = useCallback((step) => {
    if (step !== null) {
      scrollToTop();
      setActiveStep(step);
    }
  }, [state]);

  const stepsCount = 5;


const secondStepItems = state.typeId && data[name].find(item => item.type == state.typeId).materials; //.find(item => item.type == '213')

  let activeNode;
  switch (activeStep) {
    case 0: activeNode = <FirstStep onSelect={onProductSelect} items={data[name]} />; break;
    case 1: activeNode = <SecondStep state={state} onSelect={onMaterialSelect} className={'calculator-products-center'} items={secondStepItems}/>; break;
    case 2: activeNode = <ThirdStep onSelect={onServiceSelect} />; break;
    case 3: activeNode = <FourthStep onSelect={onAreaSelect} />; break;
    case 4: activeNode = <FifthStep onSubmit={onSubmit} />; break;
    case 5: activeNode = <FinalStep onSubmit={setActiveStep} />; break;
  }

  const isSix = (activeStep == 0 && firstStepItems && firstStepItems.length === 6)
    || (activeStep == 1 && secondStepItems && secondStepItems.length === 6);

  return (
    <div>
      <div className={cls("calculator-product-steps", {'calculator-product-steps-six': isSix})}>
        {activeNode}
      </div>
      <Steps activeStep={activeStep} stepsCount={stepsCount} onSelect={onStepSelect} />
    </div>
  );
}

export default Calculator;
