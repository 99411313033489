
export default function animateHover(selector, activeClassName) {
  var elements = Array.from(document.querySelectorAll(selector));

  elements.forEach((element) => {
    element.addEventListener('mouseenter', (event) => {
      const { target } = event || {};

      if (!target || target.classList.contains(activeClassName)) {
        return;
      }

      elements.forEach(element => element.classList.remove(activeClassName));

      target.classList.add(activeClassName);
    })
  });
}