import React, { useEffect, useState, useCallback, useRef } from 'react';
import './Feedback.scss';

import validate from '../validate';
import post from '../post';

function Feedback(props) {
  const { center } = props;

  const phoneRef = useRef(null);
  const emailRef = useRef(null);
  const nameRef = useRef(null);
  const commentRef = useRef(null);

  const [ isFinal, setIsFinal ] = useState(false);

  const onChange = useCallback((e) => {
    e.target.style.borderColor = 'white';
  }, []);

  const onSubmit = useCallback((e) => {
    e.preventDefault();
    const fields = [
      { input: nameRef.current},
      { input: phoneRef.current, type: 'phone'},
      { input: emailRef.current, type: 'email'},
      { input: commentRef.current},
    ];

    if (!validate(fields)) {
      setIsFinal(true);
      post(
        `/wp-admin/admin-ajax.php?action=collect_data&form=feedback`,
        {
          name: nameRef.current.value,
          phone: phoneRef.current.value,
          email: emailRef.current.value,
          comment: commentRef.current.value,
        },
        (err, res) => {
          // console.log(err, res);
        }
      );
    }
  }, [nameRef, phoneRef, emailRef, commentRef]);

  let contentNode;
  if (!isFinal) {
    contentNode = (
      <>
        <div className="feedback-inputs">
          <input className="feedback-input" placeholder="Имя" name="name" ref={nameRef} onChange={onChange} />
          <input className="feedback-input" placeholder="Телефон" name="phone" ref={phoneRef} onChange={onChange} />
        </div>
        <div className="feedback-inputs" style={{marginTop: '20px'}}>
          <input className="feedback-input" placeholder="E-mail" name="email" ref={emailRef} onChange={onChange} />
          <input className="feedback-input" placeholder="Ваше сообщение" ref={commentRef} onChange={onChange} />
        </div>
        <div className="feedback-required">* все поля обязательны для заполнения</div>
        <button className="feedback-button" onClick={onSubmit}>Оставить заявку</button>
      </>
    );
  } else {
    contentNode = (
      <>
        <div className="feedback-final">
          <div className="feedback-title">Спасибо за&nbsp;вашу&nbsp;заявку,</div>
          <div className="feedback-text">Наш менеджер свяжется с вами по указанному телефону в самое ближайшее время</div>
        </div>
      </>
    );
  }

  return contentNode;

  return (
    <div className="single-column" id="feedback" style={{marginTop: '5px'}}>
      <div className="wrapper">
        <div className="feedback-background" />
        <h1 className="feedback-h1" style={{textAlign: center ? 'center' : ''}}>Обратная связь</h1>
        <div className="description" style={{textTransform: 'none', textAlign: 'center'}}>Наш менеджер ответит на интересующие вас вопросы</div>
        {contentNode}
      </div>
    </div>
  );
}

export default Feedback;
