import React, { useEffect, useState, useCallback, useRef } from 'react';
import './Contacts.scss';

import Carousel, { Modal, ModalGateway } from 'react-images';
import {carouselComponents} from 'react-images';

const BaseView = carouselComponents.View;

// transparent pixel: http://png-pixel.com/
const pixel = {source: 'data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACH5BAEAAAAALAAAAAABAAEAAAICRAEAOw=='}
const overscan = 3

function View(props) {
  const {currentIndex, index, data} = props
  const inBounds = Math.abs(currentIndex - index) <= overscan;

  return (
    <div className="contacts-modal-image">
      <BaseView {...props} data={inBounds ? data : pixel} />
    </div>
  );
}

const images = [
  {source: "/images/map@2x.png"},
  {source: "/images/map-car@2x.jpg"},
  {source: "/images/map_storage.jpg"}
];

function Contacts() {
  const [lightboxIsOpen, setLightboxIsOpen] = useState(false);
  const [lightboxIndex, setLightboxIndex] = useState(0);

  const openLightBox = useCallback((index) => {
    if (document.documentElement.clientWidth >= 800) {
      setLightboxIndex(index);
      setLightboxIsOpen(true);
    }
  }, []);

  const closeLightBox = useCallback(() => {
    setLightboxIsOpen(false);
  }, []);

  useEffect(() => {
    var elements = Array.from(document.querySelectorAll('.contacts-map'));

    elements.forEach((element) => {
      element.addEventListener('click', (event) => {
        const { currentTarget } = event || {};
        if (document.documentElement.clientWidth >= 800) {
          setLightboxIndex(+currentTarget.getAttribute('data-index'));
          setLightboxIsOpen(true);
        }
      })
    });
  }, []);

  return (
    <ModalGateway>
      {lightboxIsOpen ? (
      <Modal onClose={closeLightBox} allowFullscreen={false}>
          <Carousel
            currentIndex={lightboxIndex}
            views={images}
            components={{View: View}}
          />
        </Modal>
      ) : null}
    </ModalGateway>
  );
  return (
    <div className="single-column">
      <div className="wrapper">
        <h1>КОНТАКТЫ</h1>
        <div className="contacts-channels">
        <ul className="contacts-channels-column contacts-info">
          <li>
            <span className="email-icon"></span>
            <span><a href="mailto:info@plasterwall.ru">info@plasterwall.ru</a></span>
          </li>
          <li>
            <span className="whatsapp-icon"></span>
            <span><a href="https://wa.me/79057087600">+7(905) 708-76-00</a></span>
          </li>
          <li>
            <a href="https://www.instagram.com/stoopen_meeus" target="_blank"><span className="instagram-icon"></span></a>
            <a href="https://www.youtube.com/channel/UCMTFrvs6LfAG-brP3wi-fbQ" target="_blank"><span className="youtube-icon"></span></a>
          </li>
        </ul>
        <ul className="contacts-channels-column contacts-phones">
          <li>
            <span className="phone-icon"></span>
            <span><a href="tel:+74952584164">+7 (495) 258-41-64</a></span>
          </li>
          <li>
            <span className="phone-icon" style={{visibility: 'hidden'}}></span>
            <span><a href="tel:+74952584165">+7 (495) 258-41-65</a></span>
          </li>
          <li>
            <span className="phone-icon" style={{visibility: 'hidden'}}></span>
            <span><a href="tel:+74952584166">+7 (495) 258-41-66</a></span>
          </li>
          <li>
            <span className="phone-icon" style={{visibility: 'hidden'}}></span>
            <span><a href="tel:+74959608067">+7 (495) 960-80-67</a></span>
          </li>
        </ul>
        <ul className="contacts-channels-column contacts-channels-adress">
          <li>
            <span className="clock-icon"></span>
            <span>Понедельник&nbsp;–&nbsp;Четверг с&nbsp;9.00&nbsp;до&nbsp;17.45</span>
          </li>
          <li>
            <span className="clock-icon" style={{visibility: 'hidden'}}></span>
            <span>Пятница&nbsp;с&nbsp;9.00&nbsp;до&nbsp;16.45</span>
          </li>
          <li>
            <span className="clock-icon" style={{visibility: 'hidden'}}></span>
            <span>Суббота&nbsp;с&nbsp;10.&nbsp;00&nbsp;до&nbsp;15.00</span>
          </li>
        </ul>
        </div>
        <div className="contacts-maps">
          <div className="contacts-left-column">
            <div className="contacts-map-title">
              СХЕМА ПРОЕЗДА К ОФИСУ
            </div>
            <div className="contacts-map-desc">
              119146, Москва, ул 1-ая Фрунзенская, д.3А&nbsp;cтр.1, (м.&nbsp;Фрунзенская), 2&nbsp;этаж, помещение&nbsp;76
            </div>
            <img className="contacts-map" src={"/images/map@2x.png"} onClick={openLightBox.bind(null, 0)} />
          </div>
          <div className="contacts-center-column">
            <div className="contacts-map-title">
              </div>
              <div className="contacts-map-desc">
              </div>
              <img className="contacts-map" src={"/images/map-car@2x.jpg"} onClick={openLightBox.bind(null, 1)} />
          </div>
          <div className="contacts-right-column">
            <div className="contacts-map-title">
              СХЕМА ПРОЕЗДА К СКЛАДУ
            </div>
            <div className="contacts-map-desc">
              Московская&nbsp;область, Ногинский&nbsp;район, посёлок Старая&nbsp;Купавна, ул.&nbsp;Дорожная,&nbsp;д.4
            </div>
            <img className="contacts-map" src={"/images/map_storage.jpg"} onClick={openLightBox.bind(null, 2)} />
          </div>
        </div>
        <ModalGateway>
          {lightboxIsOpen ? (
            <Modal onClose={closeLightBox} allowFullscreen={false}>
                <Carousel
                  currentIndex={lightboxIndex}
                  views={images}
                  // styles={{view: getViewStyles}}
                  components={{View: View}}
                />
              </Modal>
            ) : null}
          </ModalGateway>
      </div>
    </div>
  );
}

export default Contacts;
