import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import animateHover from './animateHover';
import Swiper from 'swiper';
import stickybits from 'stickybits';
// import s from './components/Nav.scss';

global.colorPickerData = {
  "stuc": [
    {
      "name": "natura",
      "value": "#f6f9ec"
    },
    {
      "name": "ivory",
      "value": "#fff8ed"
    },
    {
      "name": "palmira",
      "value": "#b58e73"
    },
    {
      "name": "papyrus",
      "value": "#f6dbb0"
    },
    {
      "name": "papyrus",
      "value": "#eecc98"
    },
    {
      "name": "gobi",
      "value": "#efbc64"
    },
    {
      "name": "pompei",
      "value": "#b9614d"
    },
    {
      "name": "knossos",
      "value": "#a27c77"
    },
    {
      "name": "celadon",
      "value": "#98846d"
    },
    {
      "name": "basait",
      "value": "#2c1e1c"
    },
    {
      "name": "veneto",
      "value": "#aa8f76"
    },
    {
      "name": "takia",
      "value": "#b38651"
    },
    {
      "name": "samaria",
      "value": "#a7aea8"
    },
    {
      "name": "agra",
      "value": "#b2977f"
    },
    {
      "name": "valeriano",
      "value": "#888a76"
    },
    {
      "name": "matala",
      "value": "#9ba78e"
    },
    {
      "name": "gabbro",
      "value": "#818783"
    },
    {
      "name": "santorini",
      "value": "#5299a5"
    },
    {
      "name": "santorini",
      "value": "#105789"
    },
    {
      "name": "nova",
      "value": "#79acb9"
    },
    {
      "name": "mari",
      "value": "#7b6345"
    },
    {
      "name": "khorsabad",
      "value": "#a78467"
    },
    {
      "name": "padua",
      "value": "#949495"
    },
    {
      "name": "doleriet",
      "value": "#83827c"
    },
    {
      "name": "cueva",
      "value": "#e38362"
    },
    {
      "name": "lascaux",
      "value": "#cf6a4d"
    },
    {
      "name": "ceylon",
      "value": "#a8b19d"
    },
    {
      "name": "parana",
      "value": "#878f85"
    },
    {
      "name": "lucca",
      "value": "#c2605b"
    },
    {
      "name": "minos",
      "value": "#b7554f"
    },
    {
      "name": "campania",
      "value": "#482b22"
    },
    {
      "name": "thera",
      "value": "#baced5"
    },
    {
      "name": "magma",
      "value": "#365466"
    },
    {
      "name": "levant",
      "value": "#dfd9cd"
    },
    {
      "name": "wool",
      "value": "#cac4a8"
    },
    {
      "name": "snow",
      "value": "#ecf2dd"
    },
    {
      "name": "enamel",
      "value": "#e7e3d5"
    },
    {
      "name": "cotton",
      "value": "#d8dcd1"
    },
    {
      "name": "ice",
      "value": "#daecde"
    },
    {
      "name": "neutilius",
      "value": "#343c34"
    },
    {
      "name": "tumuli",
      "value": "#dbe1c1"
    },
    {
      "name": "olivin",
      "value": "#acb791"
    },
    {
      "name": "ubar",
      "value": "#dbd5b4"
    },
    {
      "name": "jute",
      "value": "#c8c1a3"
    },
    {
      "name": "forum",
      "value": "#bfaf84"
    },
    {
      "name": "silk",
      "value": "#8e7a6e"
    },
    {
      "name": "winter",
      "value": "#776858"
    },
    {
      "name": "forest",
      "value": "#708b6e"
    },
    {
      "name": "rose",
      "value": "#dbbcb3"
    },
    {
      "name": "mist",
      "value": "#967261"
    }
  ],
  "kalk": [
    {
      "name": "natura",
      "value": "#f8f8ec"
    },
    {
      "name": "ivory",
      "value": "#fff9ea"
    },
    {
      "name": "palmira",
      "value": "#a58e81"
    },
    {
      "name": "papyrus",
      "value": "#f8dfb6"
    },
    {
      "name": "gobi",
      "value": "#f4c06a"
    },
    {
      "name": "pompei",
      "value": "#b9604c"
    },
    {
      "name": "knossos",
      "value": "#8a6562"
    },
    {
      "name": "celadon",
      "value": "#9c8c7c"
    },
    {
      "name": "basalt",
      "value": "#595e5c"
    },
    {
      "name": "veneto",
      "value": "#ded1bd"
    },
    {
      "name": "samaria",
      "value": "#a7aca7"
    },
    {
      "name": "vincenza",
      "value": "#a39885"
    },
    {
      "name": "matala",
      "value": "#9da790"
    },
    {
      "name": "gabbro",
      "value": "#818480"
    },
    {
      "name": "santorini",
      "value": "#5899a7"
    },
    {
      "name": "nova",
      "value": "#7eb0bd"
    },
    {
      "name": "assyria",
      "value": "#9d837d"
    },
    {
      "name": "phaistos",
      "value": "#a79989"
    },
    {
      "name": "mari",
      "value": "#787d65"
    },
    {
      "name": "akkadia",
      "value": "#ddb68f"
    },
    {
      "name": "khorsabad",
      "value": "#a98369"
    },
    {
      "name": "padua",
      "value": "#8e888b"
    },
    {
      "name": "doleriet",
      "value": "#86827d"
    },
    {
      "name": "silk",
      "value": "#c0beb2"
    },
    {
      "name": "papyrus",
      "value": "#e7c895"
    },
    {
      "name": "celadon",
      "value": "#9c8c7b"
    },
    {
      "name": "sura",
      "value": "#6f564b"
    },
    {
      "name": "cueva",
      "value": "#e18461"
    },
    {
      "name": "lascaux",
      "value": "#c5664d"
    },
    {
      "name": "cella",
      "value": "#9d8d70"
    },
    {
      "name": "lute",
      "value": "#737265"
    },
    {
      "name": "desert",
      "value": "#cbc69f"
    },
    {
      "name": "ceylon",
      "value": "#838e83"
    },
    {
      "name": "parana",
      "value": "#848c84"
    },
    {
      "name": "acre",
      "value": "#4b5b4f"
    },
    {
      "name": "visconti",
      "value": "#645454"
    },
    {
      "name": "minos",
      "value": "#b2554e"
    },
    {
      "name": "campania",
      "value": "#482b22"
    },
    {
      "name": "thera",
      "value": "#bbcfd6"
    },
    {
      "name": "lucca",
      "value": "#bd615a"
    },
    {
      "name": "lagoon",
      "value": "#125887"
    },
    {
      "name": "magma",
      "value": "#365366"
    },
    {
      "name": "levant",
      "value": "#e1dbcd"
    },
    {
      "name": "wool",
      "value": "#e7e5d6"
    },
    {
      "name": "snow",
      "value": "#edf2da"
    },
    {
      "name": "enamel",
      "value": "#eae6d2"
    },
    {
      "name": "cotton",
      "value": "#ccd3bf"
    },
    {
      "name": "ice",
      "value": "#daece0"
    },
    {
      "name": "bluestone",
      "value": "#b1bdb5"
    },
    {
      "name": "nautilus",
      "value": "#677d73"
    }
  ],
  "kalei": [
    {
      "name": "terra",
      "value": "#877d75"
    },
    {
      "name": "natura",
      "value": "#d4d2cc"
    },
    {
      "name": "ivory",
      "value": "#e2e1dc"
    },
    {
      "name": "palmira",
      "value": "#c7b9a9"
    },
    {
      "name": "papyrus",
      "value": "#ccc099"
    },
    {
      "name": "gobi",
      "value": "#c9ab5f"
    },
    {
      "name": "verona",
      "value": "#e39773"
    },
    {
      "name": "zigurrat",
      "value": "#c1966c"
    },
    {
      "name": "altamira",
      "value": "#cf8763"
    },
    {
      "name": "pompe",
      "value": "#ca8874"
    },
    {
      "name": "knossos",
      "value": "#a18b8d"
    },
    {
      "name": "jaipur",
      "value": "#8d8087"
    },
    {
      "name": "celadon",
      "value": "#c8bcae"
    },
    {
      "name": "amazone",
      "value": "#839e89"
    },
    {
      "name": "stonehenger",
      "value": "#909398"
    },
    {
      "name": "atlantis",
      "value": "#7aa1ab"
    },
    {
      "name": "basalt",
      "value": "#6d6f75"
    },
    {
      "name": "veneto",
      "value": "#c8bfad"
    },
    {
      "name": "takia",
      "value": "#bdac81"
    },
    {
      "name": "samaria",
      "value": "#aaada6"
    },
    {
      "name": "agra",
      "value": "#b8ae9c"
    },
    {
      "name": "vincenza",
      "value": "#a9a08e"
    },
    {
      "name": "mantua",
      "value": "#c0caba"
    },
    {
      "name": "valeriano",
      "value": "#a1aa96"
    },
    {
      "name": "matala",
      "value": "#a9b1a2"
    },
    {
      "name": "gabbro",
      "value": "#a1aa99"
    },
    {
      "name": "santorini",
      "value": "#6198ab"
    },
    {
      "name": "nova",
      "value": "#a2c8c8"
    },
    {
      "name": "assyria",
      "value": "#a39593"
    },
    {
      "name": "phaistos",
      "value": "#beb39e"
    },
    {
      "name": "mari",
      "value": "#6c6f56"
    },
    {
      "name": "akkadia",
      "value": "#c8b18a"
    },
    {
      "name": "khorsabad",
      "value": "#b8926c"
    },
    {
      "name": "padua",
      "value": "#b2a9a3"
    },
    {
      "name": "doleriet",
      "value": "#676565"
    },
    {
      "name": "sienna",
      "value": "#d8cfb9"
    }
  ]
};

// const rootNode = document.getElementById('root');
// if (rootNode) {
//   const App = require('./App').default;
//   ReactDOM.render(
//     <React.StrictMode>
//       <App />
//     </React.StrictMode>,
//     rootNode
//   );
// }

const masterClassForm = document.getElementById('master-class-form');
if (masterClassForm) {
  const Component = require('./components/MasterClass').default;
  ReactDOM.render(
    <React.StrictMode>
      <Component />
    </React.StrictMode>,
    masterClassForm
  );
}

const feedbackForm = document.getElementById('feedback-form');
if (feedbackForm) {
  const Component = require('./components/Feedback').default;
  ReactDOM.render(
    <React.StrictMode>
      <Component />
    </React.StrictMode>,
    feedbackForm
  );
}

const colorPickerForm = document.getElementById('color-picker-form');
if (colorPickerForm) {
  const Component = require('./components/ColorPicker').default;
  ReactDOM.render(
    <React.StrictMode>
      <Component
        name={colorPickerForm.getAttribute('data-name')}
        title={colorPickerForm.getAttribute('data-title')}
        initial={colorPickerForm.getAttribute('data-initial')}
        backgroundImage={colorPickerForm.getAttribute('data-image')}
      />
    </React.StrictMode>,
    colorPickerForm
  );
}

const calculatorForm = document.getElementById('calculator-form');
if (calculatorForm) {
  const Component = require('./components/Calculator').default;
  ReactDOM.render(
    <React.StrictMode>
      <Component name={calculatorForm.getAttribute('data-name')} />
    </React.StrictMode>,
    calculatorForm
  );
}

const portfolioImages = document.getElementById('portfolio-images');
if (portfolioImages) {
  const Component = require('./pages/Portfolio').default;
  ReactDOM.render(
    <React.StrictMode>
      <Component />
    </React.StrictMode>,
    portfolioImages
  );
}

const contactsForm = document.getElementById('contacts-form');
if (contactsForm) {
  const Component = require('./components/Contacts').default;
  ReactDOM.render(
    <React.StrictMode>
      <Component />
    </React.StrictMode>,
    contactsForm
  );
}


animateHover('.production-item', 'production-item-active');

new Swiper('.header-background-slider', {
  slidesPerView: 1,
  spaceBetween: 0,
  loop: true,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  speed: 1000,
  autoplay: {
    delay: 6000,
  },
});


new Swiper('.reviews-swiper-container', {
  // slidesPerView: 'auto',//isMobile ? 1 : 3,
  breakpoints: {
    250: {
      slidesPerView: 1,
      spaceBetween: 20
    },
    860: {
      slidesPerView: 2,
      spaceBetween: 30
    },
    1064: {
      slidesPerView: 3,
      spaceBetween: 30
    }
  },
  spaceBetween: 30,
    navigation: {
      nextEl: '.reviews-arrow-right',
      prevEl: '.reviews-arrow-left',
    },
});

stickybits('.nav-row');

const navNode = document.querySelector('nav.nav');
let isMenuOpen = false;
let timeout = 0;

function toggleMenu(e) {
  e.preventDefault();
  if (timeout > Date.now() - 300) {
    return;
  }
  timeout = Date.now();
  if (isMenuOpen) {
    isMenuOpen=false;
    navNode.classList.remove('nav-isOpen');
  } else {
    isMenuOpen=true;
    navNode.classList.add('nav-isOpen');
  }
}

const menuIcon = document.querySelector('.nav-menu-icon');
menuIcon.addEventListener('click', toggleMenu);
menuIcon.addEventListener('touchstart', toggleMenu);
